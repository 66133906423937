export default function ConvertDate(str) {
    let st1 =str.split('١').join('1')
    let st2 =st1.split('٢').join('2')
    let st3 =st2.split('٣').join('3')
    let st4 =st3.split('٤').join('4')
    let st5 =st4.split('٥').join('5')
    let st6 =st5.split('٦').join('6')
    let st7 =st6.split('٧').join('7')
    let st8 =st7.split('٨').join('8')
    let st9 =st8.split('٩').join('9')
    let st0 =st9.split('٠').join('0')
    return st0
}